$(function(){

  // $('#feature').tubular({
  //            videoId: 'CkkXBueVc3o'  // 埋め込みたいYoutubeのIDを記述します,
  //      });

  //toTopの表示・非表示
  function displayToTop(){
    var scrollTop = $(window).scrollTop();
    if (scrollTop < 300) {
      $(".to-page-top").fadeOut();
    } else {
      $(".to-page-top").fadeIn();
      $(".to-page-top a").smoothScroll({offset: 0});
    }
  }
  $(window).on("load scroll", displayToTop);
  //スマホメニュー外部クリックで非表示
  $('#navbars ul').click(function(){
    $('#navbars').removeClass('show');
  });

  $("#navbars ul li a").smoothScroll({offset: 0});

  // home
  if ($('body').hasClass('home')) {
    //背景画像設定
    $('#feature').backstretch([
      "/uploads/home-slide1.jpg",
      "/uploads/home-slide2.jpg",
      "/uploads/home-slide3.jpg"
    ], {duration: 4000, fade: 2000});
    //スクロールでロゴ非表示
    function displayLogo(){
      var scrollTop2 = $(window).scrollTop();
      if (scrollTop2 > 180) {
        $(".sitename").fadeOut(200);
      } else {
        $(".sitename").fadeIn(200);
      }
    }
    $(window).on("load scroll", displayLogo);
    // recruitスライダー
    $('.photos-slider').slick({
      autoplay: true,
      pauseOnHover: false,
      autoplaySpeed: 3000,
      speed: 1000,
      arrows: false,
      slidesToShow: 2,
      slidesToScroll: 1,
      infinite: true,
      variableWidth: true
    });
  }
});
